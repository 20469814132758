<style lang="scss">
#debug {
  display: none;
}

#frontend {
  .asgoodas-all {
    .header-intro-generel-box {
      background-position: center center !important;
    }

    .abo-box-inner {
      position: relative;

      [class*='col-'] {
        //border:1px solid red;
      }

      .z-index {

        z-index: 3;
        position: relative;

        @media(max-width:$width-lg) {
          top: -30px;
        }

        @media(max-width:$width-md) {
          top: 0px;
        }
      }

      .img-fluid {
        position: relative;
      }

      .align-top-lg {
        @media(max-width:$width-lg) {
          align-self: flex-start !important;
        }
      }

      @media (max-width: $width-xl) {}

      @media (max-width: $width-lg) {}
    }

    .img-v1 {
      z-index: 2 !important;

      @media(max-width:$width-md) {
        margin: 15px auto;
      }
    }

    .outer-border {
      position: relative;

      &:after {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 20px;
        left: -20px;
        border-width: 10px;
        border-style: solid;
        border-width: 10px;
        border-style: solid;
      }

      img {}
    }

    .outer-border-v2 {
      &:after {
        left: 20px;
      }


    }

    .border-color-coral {
      &:after {
        border-color: $coral;
      }
    }

    .border-color-mint {
      &:after {
        border-color: $sand;
      }
    }

    .border-color-wine-red {
      &:after {
        border-color: $wine-red;
      }
    }

    .img-v2 {
      z-index: 5 !important;
      margin-bottom: 50px;
      left: -60px;

      @media(max-width:$width-lg) {
        left: 0;
        margin: 0 auto 15px auto;
      }

      @media(max-width:$width-md) {
        margin: 15px auto;
      }
    }

    .img-v3 {
      z-index: 1;
      left: -60px;

      @media(max-width:$width-lg) {
        left: 0;
        margin: 10px auto;
      }
    }

    .img-v4 {
      top: -100px;
      z-index: 5 !important;

      @media(max-width:$width-xl) {
        top: -50px;
      }

      @media(max-width:$width-lg) {
        margin: 15px auto;
        top: 10px;
      }

      @media(max-width:$width-md) {
        top: 0;
        margin: 15px auto;
      }
    }

    .img-v5 {
      top: -28px;

      @media(max-width:$width-lg) {
        top: 0px;
        margin: 15px auto;
      }

    }

    h3 {
      font-family: 'Helvetica Neue LT W05_75 Bold';
      text-transform: none;
      font-size: 140%;
    }

    h4 {
      font-family: 'Helvetica Neue LT W05 56 Italic';
      margin-bottom: 30px;
      text-transform: none;
      font-size: 140%;
    }

    .person-box {
      position: relative;

      .zitat-box {

        font-family: 'American Typewriter ITC W04 Md';
        line-height: 1.5;
        text-align: center;
        text-transform: none;
        font-size: 160%;
        position: absolute;
        padding: 30px;
        max-width: 450px;

        @media(max-width:$width-xl) {
          position: relative;
          max-width: 100%;

        }

        @media(max-width:$width-xl) {
          font-size: 120%;
        }

        .zitat-icon {
          display: block;
          margin: 0 auto;
        }

        span {
          display: block;
          margin-bottom: 0;
          font-size: 18px;
          text-transform: uppercase;
          letter-spacing: 2px;
          font-family: 'Helvetica Neue LT W05 65 Medium';
          margin-top: 10px;

          @media(max-width:$width-lg) {
            font-size: 16px;
          }

          .firma {
            margin-top: 5px;
            display: block;
            font-size: 14px;
            font-family: 'Helvetica Neue LT W05 45 Light';
          }
        }
      }

      .top-left {
        top: 150px;
        left: -100px;

        @media (max-width: $width-xl) {
          top: 0;
          left: 0;
        }
      }

      .top-right {
        top: 150px;
        right: -100px;

        @media (max-width: $width-xl) {
          top: 0;
          right: 0;
        }
      }

      .txt-box {
        text-align: left;
        padding: 60px 15px;
        max-width: 80%;
        margin-left: auto;
        margin-right: auto;

        @media(max-width:$width-lg) {
          max-width: 100%;
          padding: 30px 20px;
        }

        .title {
          font-family: 'Helvetica Neue LT W05 35 Thin';
          letter-spacing: 2px;
          font-size: 200%;
        }

        p {
          strong {
            display: block;
            font-family: 'Helvetica Neue LT W05_75 Bold';
          }
        }
      }

      .box-middle {
        border: 1px solid #878787;
      }
    }


  }
}
</style>

<template>
  <SocialMediaIconBox />
  <div class="asgoodas-all">
    <!--<HeaderImageBoxGenerel :style="{ 'background-image': `url(${require('@/assets/img/karriere/header-img-top.jpg')})` }"  :headerImageText="$t('General.as-good-as-our-people')" />-->
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="headline-box-std">
            <div class="headline-inner">
              <div class="breadcrumb"><span>{{ $t('General.karriere') }}</span></div>
              <h1 v-html="$t('General.as-good-as-our-people-v2')"></h1>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row justify-content-center padding-t-m padding-b-xl">
        <div class="col-lg-8 col-md-7">
          <p class="intro-txt text-center font-helv-medium">{{ $t('as-good-as-our-people.intro-txt') }}</p>

        </div>
      </div>
    </div>

    <div class="container">
      <div class="abo-box-inner">
        <div class="row justify-content-center align-self-center">
          <div class="col-lg-5 col-md-6 col-sm-8 col-9 align-self-center">
            <img src="@/assets/img/karriere/as-good/v1.jpg" alt="as good as our people" class="img-fluid img-v1" />
          </div>
          <div class="col-lg-7 col-md-6 col-sm-8 col-9 align-self-center align-top-lg">
            <div class="outer-border border-color-coral img-v2">
              <img src="@/assets/img/karriere/as-good/v2.jpg" alt="as good as our people" class="img-fluid" />
            </div>
            <div class="outer-border outer-border-v2 border-color-wine-red img-v3" v-inview="'move-in-from-right'">
              <img src="@/assets/img/karriere/as-good/v3.jpg" alt="as good as our people" class="img-fluid" />
            </div>

          </div>
        </div>
        <div class="row align-self-center justify-content-center z-index">
          <div class="col-lg-7 col-md-5 col-sm-8 col-9 justify-content-end align-self-center"
            v-inview="'move-in-from-left'">
            <div class="outer-border border-color-mint img-v4">
              <img src="@/assets/img/karriere/as-good/v5.jpg" alt="as good as our people" class="img-fluid" />
            </div>
          </div>
          <!--
          <div class="col-lg-5 col-md-7 col-sm-8 col-9 align-self-center" v-inview="'move-in-from-right'">
            
            <img src="@/assets/img/karriere/as-good/v4.jpg" alt="as good as our people" class="img-fluid img-v5" />
          </div>
          -->
        </div>

      </div>
    </div>

    <!--<div class="container margin-t-l">
      <div class="row justify-content-center">
        <div class="col-lg-10">
          <div class="person-box">
            <img src="@/assets/img/karriere/iris-bonavida.jpg" alt="Iris Bonavida - Kleine Zeitung" class="img-fluid">
            <div class="zitat-box top-left bg-mint"><Icon :path="mdiFormatQuoteClose" class="zitat-icon" />{{$t('as-good-as-our-people.dejana-pusch-hl-v1')}}<span>Dejana Puschnig <span class="firma"></span></span></div>
            <div class="box-middle">
              <div class="txt-box">

                <h3>{{$t('as-good-as-our-people.dejana-pusch-hl-v1')}} </h3>
                <p>{{$t('as-good-as-our-people.dejana-pusch-txt-v1')}}</p>
                <h3>{{$t('as-good-as-our-people.dejana-pusch-hl-v2')}} </h3>
                <p>{{$t('as-good-as-our-people.dejana-pusch-txt-v2')}}</p>
                <h3>{{$t('as-good-as-our-people.dejana-pusch-hl-v3')}} </h3>
                <p>{{$t('as-good-as-our-people.dejana-pusch-txt-v3')}}</p>
                <p></p>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>-->



  </div>
  <Footer />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import SocialMediaIconBox from '../../../components/Frontend/SocialMediaIconBox.vue';
import Footer from '../../../components/Frontend/Footer.vue';
import HeaderImageBoxGenerel from '../../../components/Frontend/HeaderImageBoxGenerel.vue';



import { mdiChevronUp } from '@mdi/js';

export default defineComponent({
  meta: {
    de: {
      title: 'As good as our people | Styria Media Group',
      keywords: [''],
      description: 'As good as our people.',
      image: require('@/assets/img/og/karriere.png'),

    },
    en: {
      title: 'As good as our people | Styria Media Group',
      keywords: [''],
      description: 'As good as our people.',
      image: require('@/assets/img/og/karriere.png'),
    },
  },
  components: {
    SocialMediaIconBox,
    //HeaderImageBoxGenerel,
    Footer,


  },
  setup() {
    return {
      mdiChevronUp,
    };
  },
});
</script>
